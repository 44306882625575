<template>
  <v-refresh @on-refresh="refresh" @on-load="loadMore" ref="refresh" :isEmpty="noData">
    <slot v-if="loaded && (!noData&&!error)"></slot>
    <div class="no-data" :style="'height:'+height"
         style="display: flex;flex-direction: column;align-items: center;justify-content: center;" v-else>
      <template v-if="!error">
        <div class="empty" v-if="!loading">
          <template v-if="!src">
            <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png">
          </template>
          <template v-else>
            <img :src="`https://cdn.001ppt.cn/h5/static/image/${src}.png`">
          </template>
          <span>{{ text }}</span>
          <a href="javascript: void (0)" v-if="btn" class="route btn" @click="goRoute">{{ btn }}</a>
        </div>
      </template>
      <template v-else>
        <van-icon name="info" class="error-icon"/>
        <a href="javascript: void (0)" class="btn error-btn" @click="getData">请求失败, 重试</a>
      </template>
    </div>
  </v-refresh>

</template>

<script>
import vRefresh from './refresh'
import {sleep} from "../config/util";

export default {
  name: "scroll",
  components: {vRefresh},
  props: {
    http: Function,
    listFilter: {
      type: Object,
      default() {
        return {}
      }
    },
    height: {
      type: String,
      default: '100%'
    },
    autoLoad: {
      type: Boolean,
      default: true
    },
    size: {
      type: Number,
      default: 10
    },
    src: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: '噢！这可能有点儿空 (⊙v⊙)'
    },
    btn: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      pageParams: {
        current: 1,
        size: 0
      },
      params: {},
      error: false,
      loaded: false,
      noData: false,
      list: [],
      totalPage: 1000,
      loading: true
    }
  },

  created() {
    this.pageParams.size = this.size;
    if (this.autoLoad) {
      this.getData();
    }
  },

  methods: {

    async getData(refresh, params) {
      this.$loading();
      this.loading = true
      try {
        const prom = this.http({...this.pageParams, ...this.listFilter, ...params});
        await sleep(500);
        const res = await prom;

        this.loaded = true;
        if (res.code !== 1) {
          this.error = true;
          this.$emit('success', []);
          return this.$refs.refresh.close(true, refresh);
        }
        this.totalPage = res.data.pages || res.data.total;
        this.noData = res.data.records.length === 0;
        this.$refs.refresh.close(this.pageParams.current * this.pageParams.size >= this.totalPage, refresh);
        if (refresh) {
          this.list = res.data.records;
        } else {
          this.list = this.list.concat(res.data.records);
        }
        this.$emit('success', this.list);
        this.$emit('pageData', {
          current: res.data.current,
          size: res.data.size,
          total: res.data.total
        })
      } catch (e) {
        throw e;
      } finally {
        this.$loading.close();
        this.loading = false
      }
    },

    refresh() {
      this.pageParams.current = 1;
      this.getData(true);
    },
    loadMore() {
      if (this.totalPage === this.pageParams.current) return;
      this.pageParams.current += 1;
      this.getData();
    },


    goRoute() {
      this.$router.push('/home');
    }
  }
}
</script>

<style lang="less" scoped>
.empty {
  margin-top: -120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>

<style>
.error-icon {
  font-size: 45px;
  color: red;
}

.error-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 40px;
  color: #999;
  border-radius: 30px;
}

.no-data img {
  width: 150px;
}

.no-data span {
  margin-top: 10px;
  color: #999;
  font-size: 12px;
  opacity: .25;
}

.no-data .route {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  width: 140px;
  height: 40px;
  color: var(--text-color);
  font-size: 14px;
  border-radius: 21px;
}
</style>
