<template>
  <van-pull-refresh class="refresh-list" v-model="isRefresh" @refresh="onRefresh">
    <van-list
      style="height: 100%"
      v-model="loadingMore"
      :finished="finished"
      offset="50"
      :immediate-check="false"
      :finished-text="isEmpty?'':'灵感到底了~'"
      @load="onLoad">
      <slot/>
    </van-list>
    <!--<div v-if="finished" style="margin-top: 8px"></div>-->
  </van-pull-refresh>

</template>

<script>
  export default {
    name: "v-refresh",
    props: ['isEmpty'],
    data() {
      return {
        isRefresh: false,
        loadingMore: false,
        finished: false,
        loadEnd: false,
      }
    },
    created() {
      // // console.log(this.$slots.default);
    },
    methods: {


      //刷新完成
      close(isFinished, refresh) {
        this.loadEnd = true;
        this.isRefresh = false;
        this.loadingMore = false;
        this.finished = isFinished;
        if (refresh) {
          try {
            document.querySelector('.refresh-list').scrollTop = 0;
          } catch (e) {

          }
        }

      },
      //刷新
      onRefresh() {
        this.finished = false;
        this.$emit('on-refresh');
      },

      onLoadEnd(s) {
        this.loadingMore = false;
        this.finished = s;
      },

      //加载更多
      onLoad() {
        this.$emit('on-load', this.onLoadEnd);
      }
    }
  }
</script>

