<template>
    <div class="container">
        <v-header></v-header>
        <div class="scroll-container">
            <van-tabs v-model="active" background="#fff" animated swipeable>
                <van-tab>
                    <template #title>
                        <span class="tab-title">粉丝</span>
                    </template>
                    <v-scroll ref="fans" height="calc(100vh - 50px - var(--header-height)" class="tab-body" :http="fansApi" @success="loadedFans" :listFilter="{userId}">
                        <template v-for="item in fans">
                            <v-cell v-if="item.focusUserId" :detail="item" @handle-click="cancelFocus" btn="已关注"></v-cell>
                            <v-cell v-else :detail="item" @handle-click="focusUser" btn="关注" type="default"></v-cell>
                        </template>
                    </v-scroll>

                </van-tab>
                <van-tab>
                    <template #title>
                        <span class="tab-title">关注</span>
                    </template>
                    <v-scroll ref="focus" height="calc(100vh - 50px - var(--header-height))" class="tab-body" :http="focusApi" @success="loadedFocus" :listFilter="{userId}">
                        <template v-for="item in focus">

                            <template  v-if="userId">
                                <v-cell v-if="item.focusUserId" :detail="item" @handle-click="cancelFocus" btn="已关注"></v-cell>
                                <v-cell v-else :detail="item" @handle-click="focusUser" btn="关注" type="default"></v-cell>
                            </template>
                            <template v-else>
                                <v-cell :detail="item" @handle-click="cancelFocus" btn="已关注"></v-cell>
                            </template>

                        </template>
                    </v-scroll>
                </van-tab>

            </van-tabs>
        </div>
    </div>
</template>

<script>
    import vScroll from '@/components/scroll'
    import VCell from '@/components/cell'
    import {user} from '@/api'
    import {mapGetters} from 'vuex'
    export default {
        name: "fansAndFocus",
        components: {vScroll,VCell},
        computed: {...mapGetters(['userInfo'])},
        data(){
            return{
                active: 0,
                fansApi: user.myFans,
                focusApi: user.myFocus,
                fans: [],
                focus: [],
                userId: null,
                title: ''
            }
        },
        created(){
          try {
              this.userId = this.$route.query.userId;
              this.active = this.$route.query.active;
          }catch (e) {

          }
        },
        methods:  {
            loadedFans(data){
                this.fans = data;
            },
            loadedFocus(data){
                this.focus = data;
            },

            otherPerson(){

            },

            //关注用户
            async focusUser({id}){
                const {code} = await user.focus({toUserId: id, userId: this.userInfo.id});
                if(code != 1) return ;
                this.$refs.focus.getData(true);
                this.$refs.fans.getData(true);
            },


            //取消关注
            async cancelFocus({id}){
                const {code} = await user.cancelFocus(id);
                if(code != 1) return;
                this.$refs.focus.getData(true);
                this.$refs.fans.getData(true);

            },
        }
    }
</script>

<style lang="less">


    .tab-body{
        height: calc(100vh - 50px - var(--header-height));
    }
</style>
